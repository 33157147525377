import get from "lodash/get";

export const setFeatureFlagsInLocalStorage = (flags: any) => {
  //   console.log("Setting feature flags -", flags);
  const flagsMap = JSON.stringify(flags || {});
  localStorage.setItem("ld-flag", flagsMap);
};

export const getFeatureFlag = (key: string) => {
  //   console.log("GET: ", localStorage.getItem("ld-flag"));
  const storeFlagFeatures = localStorage.getItem("ld-flag") || "{}";
  const flags = JSON.parse(storeFlagFeatures);

  return get(flags, key, undefined);
};

export const getAllFeatureFlags = (): any => {
  let flags = {};
  try {
    const storeFlagFeatures = localStorage.getItem("ld-flag") || "{}";
    flags = JSON.parse(storeFlagFeatures);
  } catch (err) {
    console.error("Error parsing store flag features");
  }

  return flags;
};
