import GlobalSpinner from "components/common/GlobalSpinner";
import { setFeatureFlagsInLocalStorage } from "FeatureFlag/service/featureFlagInstance";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";

const App = React.lazy(
  () =>
    import(
      /* webpackChunkName: "root.app" */
      "./App"
    )
);

const FlagsLoadedApp = ({ flags: featureFlags }: any) => {
  const [ldFlagsLoaded, setLdFlagsLoaded] = useState(false);

  useEffect(() => {
    if (featureFlags) {
      setFeatureFlagsInLocalStorage(featureFlags);
      setLdFlagsLoaded(true);
    }
  }, [featureFlags]);

  if (ldFlagsLoaded) {
    return <App />;
  }

  return <GlobalSpinner />;
};

export default withLDConsumer()(FlagsLoadedApp);
