import React from "react";
import ReactDOM from "react-dom";

import {
  createInstance as createOptimizelyReactInstance,
  OptimizelyProvider,
} from "@optimizely/react-sdk";
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk";

import "./index.scss";
import "antd/dist/antd.less";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { generateOptimizelyEnvKey } from "Optimizely/service/generateOptimizelyEnvKey";

import GlobalSpinner from "./components/common/GlobalSpinner";

import getSchemaName from "utils/getSchemaName";
import { overrideDataFile } from "Optimizely/service/utils";
// !!! DO NOT IMPORT APPCONFIG IN INDEX FILE
import { generateLDEnvKey } from "FeatureFlag/service/generateLDEnvKey";
import FlagsLoadedApp from "./FlagsLoadedApp";

const rootDOM = document.getElementById("root");

// TODO: Move to index.html
require("typeface-roboto");

window.__OPTIMIZELY__ = window.__OPTIMIZELY__ || {};
window.__OPTIMIZELY__.sdkKey = generateOptimizelyEnvKey();
window.__OPTIMIZELY__.reactInstance = null;
window.__OPTIMIZELY__.readyResult = null;

//PLAT-4142 Added to capture full page load time
// console.log(`Page Load Time to API completion using date App.js`, Date.now());
// console.log("startTime", Date.now());
sessionStorage.setItem("startTime", Date.now());

// Launch Darkly
async function setupLDprovider() {
  let LDProvider;
  try {
    const schemaName = getSchemaName();

    let context = {
      kind: "multi",
      tenant: {
        kind: "tenant",
        key: schemaName,
        name: schemaName,
      },
    };

    LDProvider = await asyncWithLDProvider({
      clientSideID: generateLDEnvKey(),
      options: {
        baseUrl: "https://launchdarkly.thelevel.ai",
        streamUrl: "https://launchdarkly.thelevel.ai",
        logger: basicLogger({ level: "warn" }),
      },
      context,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    });
  } catch (err) {
    console.log(err);
  }
  return LDProvider;
}

// Move all non-pure files in here
async function postOptimizelyCallback() {
  // !!! Will be all cleaned when only Launch-darkly is used for feature flag
  const optimizelyReactInstance = window.__OPTIMIZELY__.reactInstance;
  const LDProvider = await setupLDprovider();

  ReactDOM.render(
    <React.Suspense fallback={<GlobalSpinner />}>
      <LDProvider>
        <OptimizelyProvider optimizely={optimizelyReactInstance}>
          <FlagsLoadedApp />
        </OptimizelyProvider>
      </LDProvider>
    </React.Suspense>,
    rootDOM
  );
  window.removeEventListener("optimizely-init", postOptimizelyCallback);
}

// Attaching event Listener immediately
window.addEventListener("optimizely-init", postOptimizelyCallback);

// Check if event was missed
if (window.__OPTIMIZELY__.reactInstance) {
  postOptimizelyCallback();
} else {
  ReactDOM.render(<GlobalSpinner />, rootDOM);
}

fetch(`https://optimizely.thelevel.ai?sdkKey=${window.__OPTIMIZELY__.sdkKey}`)
  .then((response) => {
    if (!response.ok || response.status >= 400) {
      throw new Error("Bad Optimizely Response");
    }
    return response.json();
  })
  .then((datafile) => {
    window.__OPTIMIZELY__.datafile = datafile;
    if (!["PRODUCTION"].includes(process.env.REACT_APP_ENVIRONMENT)) {
      return overrideDataFile(datafile);
    }
    return datafile;
  })
  .then((datafile) => {
    const optimizelyReactInstance = createOptimizelyReactInstance({
      datafile,
      logLevel: 4,
    });
    return optimizelyReactInstance;
  })
  .catch(() => {
    // Create default Optimizely Instance
    const optimizelyReactInstance = createOptimizelyReactInstance({
      sdkKey: window.__OPTIMIZELY__.sdkKey,
      logLevel: 4,
    });
    return optimizelyReactInstance;
  })
  .then(async (optimizelyReactInstance) => {
    const schemaName = getSchemaName();
    optimizelyReactInstance.setUser({
      id: `user_${schemaName}`,
      attributes: { tenant: schemaName },
    });
    window.__OPTIMIZELY__.reactInstance = optimizelyReactInstance;
    window.__OPTIMIZELY__.readyResult = await optimizelyReactInstance.onReady();
    return;
  })
  .finally(() => {
    window.dispatchEvent(new CustomEvent("optimizely-init"));
  });
